import React from 'react'
import { Card, Container, Row, Button, Col, Image } from 'react-bootstrap';
import { CheckCircle } from 'react-bootstrap-icons';
import PT30 from "../img/PT30.jpg"

export default function Products() {
  return (
    <>
      <Container style={{height:"200px"}}></Container>
      <Container className="text-center cardsColection"> 
          <Row className="justify-content-center"> 
              <h1>Our Products</h1>
          </Row>
          <Row className="justify-content-center"> <p  className="myHeader-subtitle">Working hard for something we don’t care about is called stressed, working hard for something we love is called passion.</p></Row>
          <Row className="justify-content-center">
            <Col lg={4} xs={12}>   
              <Card>
                <Card.Body>
                  <Card.Title>Pro</Card.Title>
                  <Card.Title className='price'>$99.00</Card.Title>
                  <Card.Text className='priceSub'>Billed Monthly</Card.Text>
                  <Card.Text>
                    <Row>
                      <Col className="cardListIcon" lg={4} xs={4}><CheckCircle color="#00ff55" size={30}/></Col>
                      <Col lg={8} xs={8}><p className="cardListText">Electronic Logbook</p></Col>
                    </Row>
                    <Row>
                      <Col className="cardListIcon" lg={4} xs={4}><CheckCircle color="#00ff55" size={30}/></Col>
                      <Col lg={8} xs={8}><p className="cardListText">Electronic DVIR</p></Col>
                    </Row>
                    <Row>
                      <Col className="cardListIcon" lg={4} xs={4}><CheckCircle color="#00ff55" size={30}/></Col>
                      <Col lg={8} xs={8}><p className="cardListText">Fleet Manager Portal</p></Col>
                    </Row>
                    <Row>
                      <Col className="cardListIcon" lg={4} xs={4}><CheckCircle color="#00ff55" size={30}/></Col>
                      <Col lg={8} xs={8}><p className="cardListText">GPS Fleet Tracking</p></Col>
                    </Row>
                    <Row>
                      <Col className="cardListIcon" lg={4} xs={4}><CheckCircle color="#00ff55" size={30}/></Col>
                      <Col lg={8} xs={8}><p className="cardListText">IFTA Reporting</p></Col>
                    </Row>
                  </Card.Text>
                  <Button style={{width:"max-content"}} size="lg" variant="outline-info"><b>Get Started</b></Button>
                </Card.Body>
              </Card>
            </Col>


            <Col lg={4} xs={12}> 
              <Card>
                <Card.Body>
                  <Card.Title>Enterprise</Card.Title>
                  <Card.Title className='price'>Contact Us</Card.Title>
                  <Card.Text className='priceSub'>Reach out for a quote</Card.Text>
                  <Card.Text>
                  <Row>
                      <Col className="cardListIcon" lg={4} xs={4}><CheckCircle color="#00ff55" size={30}/></Col>
                      <Col lg={8} xs={8}><p className="cardListText">Electronic Logbook</p></Col>
                    </Row>
                    <Row>
                      <Col className="cardListIcon" lg={4} xs={4}><CheckCircle color="#00ff55" size={30}/></Col>
                      <Col lg={8} xs={8}><p className="cardListText">Electronic DVIR</p></Col>
                    </Row>
                    <Row>
                      <Col className="cardListIcon" lg={4} xs={4}><CheckCircle color="#00ff55" size={30}/></Col>
                      <Col lg={8} xs={8}><p className="cardListText">Fleet Manager Portal</p></Col>
                    </Row>
                    <Row>
                      <Col className="cardListIcon" lg={4} xs={4}><CheckCircle color="#00ff55" size={30}/></Col>
                      <Col lg={8} xs={8}><p className="cardListText">GPS Fleet Tracking</p></Col>
                    </Row>
                    <Row>
                      <Col className="cardListIcon" lg={4} xs={4}><CheckCircle color="#00ff55" size={30}/></Col>
                      <Col lg={8} xs={8}><p className="cardListText">IFTA Reporting</p></Col>
                    </Row>
                    <Row>
                      <Col className="cardListIcon" lg={4} xs={4}><CheckCircle color="#00ff55" size={30}/></Col>
                      <Col lg={8} xs={8}><p className="cardListText">Logbook Monitoring</p></Col>
                    </Row>
                    <Row>
                      <Col className="cardListIcon" lg={4} xs={4}><CheckCircle color="#00ff55" size={30}/></Col>
                      <Col lg={8} xs={8}><p className="cardListText">24/7 support</p></Col>
                    </Row>
                  </Card.Text>
                  <Button style={{width:"max-content"}} size="lg" variant="outline-info"><b>Let's Talk</b></Button>
                </Card.Body>
              </Card>
            </Col> 
          </Row>
      </Container>
    </>
  )
}
