import React, { useRef } from 'react'
import "../App.css"
import { Button, Container, Row, Col} from 'react-bootstrap';
import { Clock, Telephone, Envelope } from 'react-bootstrap-icons';
import video from "../video/video.mp4"
import androidApp from "../img/androidApp.png"
import appleApp from "../img/appleApp.png"
import { isBrowser } from "react-device-detect";
import { useHistory, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import useOnScreen from "../Functions/useOnScreen"


// const $ = (id) => document.getElementById(id);s
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const variants = {
    active: {
        x: [getWindowDimensions().width,0],
        scale:[0,1],
        transition:
        {
            duration: 1.5
        }
    },
    inActive: {
        x: [0,getWindowDimensions().width],
        transition:
        {
            duration: 1.5
        }
    },
    activeRev: {
        x: [-getWindowDimensions().width,0],
        scale:[0,1],
        transition:
        {
            duration: 1.5
        }
    },
    inActiveRev: {
        x: [0,-getWindowDimensions().width],
        transition:
        {
            duration: 1.5
        }
    }
};

export default function Homepage() {
    const location = useLocation();
    const history = useHistory();
    
    // function goToApplication(e) {
    //     e.preventDefault();
    //     window.scrollTo(0, 100);
    //     history.push('/application')
    // }

    function goToProducts(e) {
        e.preventDefault();
        window.scrollTo(0, 100);
        history.push('/products')
    }

    function Background() {
        if(location.pathname === "/") {
            return (
                isBrowser ? 
                    <div className="background">
                        <video id='videoObj' style={{width:"100%"}} autoPlay muted loop>
                            <source src={video} type="video/mp4" />
                            Your browser does not support HTML video.
                        </video>
                        <div class="overlay">
                            <h1 className="myHeader-title">Fleet Management System with Advanced Features</h1>
                            <h3 className="myHeader-subtitle">The most user-friendly ELD device and app</h3>
                            <a className="myHeader-btn btn btn--primary btn--rounded" href="\" onClick={goToProducts}>Get Started</a>
                        </div>
                    </div> 
                
                : 
                    <div className="background-mobile" style={{height:`${getWindowDimensions().height}px`}}>
                        <video id='videoObj' style={{paddingBottom:"50px"}} autoPlay muted loop>
                            <source src={video} type="video/mp4" />
                            Your browser does not support HTML video.
                        </video>
                        <Container>
                            <Row className="text-center"><h1 className="myHeader-title">Fleet Management System with Advanced Features</h1></Row>
                            <Row className="text-center"><h3 className="myHeader-subtitle-mobile">The most user-friendly ELD device and app</h3></Row>
                            <Row className="text-center"><div><Button onClick={goToProducts} className="myHeader-btn btn btn--primary btn--rounded" href="/application">Get Started</Button></div></Row>
                        </Container>
                    </div> 
            )
        }
        return (<Container style={{height:"100px"}}/>)
    }

    function HomeItemBoxes()
    {
        const ref = useRef();
        const ref1 = useRef();
        const ref2 = useRef();
        const ref3 = useRef();
    
        const isVisible = useOnScreen(ref);
        const isVisible1 = useOnScreen(ref1);
        const isVisible2 = useOnScreen(ref2);
        const isVisible3 = useOnScreen(ref3);

        if(!isBrowser)
        {
            return(
                <Container className="bg-dark homeItemsContainer">
                    <Row className="text-center" style={{color:"white"}}>
                        <Col ref={ref} xs={12} md={3} className="mt-5 homeItemsBox">
                            <motion.div style={isVisible ? { x: getWindowDimensions().width} : {x: 0}} animate={isVisible ? "active" : "inActive"} variants={variants} className="homePageIcons">
                                <Row> <Clock color="#f7a120" size={80}/> </Row>
                                <Row className="mt-3"> <b className="font-weight-bold">Contact Us 24/7</b> </Row>
                            </motion.div>
                        </Col>
                        <hr />
                        <Col ref={ref1} xs={12} md={3} className="homeItemsBox">
                            <motion.div style={isVisible1 ? { x: getWindowDimensions().width} : {x: 0}} animate={isVisible1 ? "activeRev" : "inActiveRev"} variants={variants} className="homePageIcons">
                                <Row> <Telephone color="#f7a120" size={80}/> </Row>
                                <Row className="mt-3"> <b className="font-weight-bold">+1 (636) 204-5424</b> </Row>
                            </motion.div>
                        </Col>
                        <hr />
                        <Col ref={ref2} xs={12} md={3} className="lg-3 homeItemsBox">
                            <motion.div style={isVisible2 ? { x: getWindowDimensions().width} : {x: 0}} animate={isVisible2 ? "active" : "inActive"} variants={variants} className="homePageIcons">
                                <Row> <Envelope color="#f7a120" size={80}/> </Row>
                                <Row className="mt-3"> <b className="font-weight-bold">info@dynamiceld.us</b> </Row>
                            </motion.div>
                        </Col>
                        <hr />
                        <Col ref={ref3} xs={12} md={3} className="justify-content-center homeItemsBox mt-5">
                            <motion.div style={isVisible3 ? { x: getWindowDimensions().width} : {x: 0}} animate={isVisible3 ? "activeRev" : "inActiveRev"} variants={variants} className="homePageIcons">
                                <a href="mailto:info@dynamiceld.us"><Button style={{width: "60%"}} size="lg" variant="outline-warning"><b>Send Inquiry</b></Button></a>
                            </motion.div>
                        </Col>
                    </Row>
                </Container>
            )
        }
        else{
            return (
                <div style={{height:"auto"}} className="bg-dark colCust">
                    <Row className="text-center" style={{color:"white"}}>
                        <Col ref={ref} xs={12} md={3} className="mt-5 mb-5">
                            <Row> <Clock color="#f7a120" size={80}/> </Row>
                            <Row className="mt-3"> <b className="font-weight-bold">Contact Us 24/7</b> </Row>
                        </Col>
                        <Col  ref={ref1} xs={12} md={3} className="mt-5 mb-5">
                            <Row> <Telephone color="#f7a120" size={80}/> </Row>
                            <Row className="mt-3"> <b className="font-weight-bold">+1 (636) 204-5424</b> </Row>
                        </Col>
                    
                        <Col ref={ref2} xs={12} md={3} className="lg-3 mt-5 mb-5">
                            <Row> <Envelope color="#f7a120" size={80}/> </Row>
                            <Row className="mt-3"> <b className="font-weight-bold">info@dynamiceld.us</b> </Row>
                        </Col>
                        
                        <Col ref={ref3} xs={12} md={3} className="justify-content-center mt-5 mb-5">
                            <a href="mailto:info@dynamiceld.us"> <Button className="mt-5" style={{width: "100%"}} size="lg" variant="outline-warning"><b>Send Inquiry</b></Button> </a>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    return (
        <div className="containerDiv">
            <Background />
            <HomeItemBoxes />

            <Container className="space" style={{ height: '50px' }} />

            <Container className="text-center mt-5">
                <Row className="justify-content-center">
                    <Col className="mb-5" lg="6">    
                        <Row className="appHeader"> <h1>ELD app to enhance your fleet</h1> </Row>
                        <Row className="appSub">
                            
                            <p>
                                DYNAMIC ELD is a streamlined, cutting-edge electronic logbook 
                                that offers DOT compliance at your fingertips. Designed to optimize 
                                operations and reduce paperwork, this intuitive logging solution can 
                                be additioned with real-time GPS tracking of your fleet, vehicle 
                                diagnostics, IFTA calculations, and more without sacrificing performance
                                or accuracy. The app syncs with the online portal to display all drivers'
                                HOS information to the fleet manager, improving dispatch tasks and truck
                                safety. Download with DYNAMIC ELD and keep on trucking.
                            </p>
                            
                        </Row>
                    </Col>
                    <Row className="justify-content-center">
                        <Col className="mb-5" lg={3} xs={6}>
                            <a href="https://play.google.com/store/apps/details?id=com.dynamiceld.mobile">
                                <img 
                                    src={androidApp}
                                    width="100%"
                                    height="auto"
                                    className="d-inline-block align-top logoApp"
                                    alt="React Bootstrap logo"
                                />
                            </a>
                        </Col>
                        <Col className="mb-5" lg={3} xs={6}>
                            <a href="https://apps.apple.com/us/app/dynamic-eld/id1631536235?l=ru">
                                <img 
                                    src={appleApp}
                                    width="100%"
                                    height="auto"
                                    className="d-inline-block align-top logoApp"
                                    alt="React Bootstrap logo"
                                />
                            </a>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Button onClick={goToProducts} style={{width:"max-content"}} size="lg" variant="outline-warning"><b>Order Now</b></Button>
                    </Row>
                </Row>
            </Container>

            <Container className="space" style={{ height: '100px' }} />
        </div>
    )
}
