import React from 'react'
import { Card, Container, Row, Button, Col, Image } from 'react-bootstrap';
import { CheckCircle } from 'react-bootstrap-icons';
import PT30 from "../img/PT30.jpg"
import CABLE1 from "../img/cable1.jpg"
import CABLE2 from "../img/cable2.png"
import CABLE3 from "../img/cable3.png"
import CABLE4 from "../img/cable4.png"
import CABLE5 from "../img/cable5.png"
import CABLE6 from "../img/cable6.png"
import ITEM1 from "../img/item2.png"
import "../App.css"

function Store() {
    return (
        <>
            <Container style={{height:"200px"}}></Container>
            <Container className="text-center cardsColection"> 

                <Row className="store-row justify-content-center py-5">
                    <Col lg={4} xs={12}>   
                        <Card style={{height:"600px"}}>
                            <Card.Body>
                                <div style={{paddingBottom:"30px", overflow:"hidden", height:"400px", justifyContent:"center", justifyItems:"center", alignItems:"center", display:"flex"}}>
                                    <Card.Img  src={PT30} />
                                </div>
                            <div style={{borderTop:"solid", paddingTop:"20px"}}>
                                <h3 style={{fontSize:"20px"}}>PT30 ELD Device</h3>
                                <br></br>
                                <a href="mailto:info@dynamiceld.us"><Button size="lg" variant="outline-info"><b>Contact Us</b></Button></a>
                            </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} xs={12}>   
                        <Card style={{height:"600px"}}>
                            <Card.Body>
                                <div style={{paddingBottom:"30px", overflow:"hidden", height:"400px", justifyContent:"center", justifyItems:"center", alignItems:"center", display:"flex"}}>
                                    <Card.Img src={CABLE1} />
                                </div>
                                <div style={{borderTop:"solid", paddingTop:"20px"}}>
                                    <h3 style={{fontSize:"20px"}}>PT30 - OBD II 16 Pin cable (heavy duty Mack/Volvo only)</h3>
                                    <br></br>
                                    <a href="mailto:info@dynamiceld.us"><Button size="lg" variant="outline-info"><b>Contact Us</b></Button></a>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} xs={12}>   
                        <Card style={{height:"600px"}}>
                            <Card.Body>
                                <div style={{paddingBottom:"30px", overflow:"hidden", height:"400px", justifyContent:"center", justifyItems:"center", alignItems:"center", display:"flex"}}>
                                    <Card.Img  src={CABLE2} />
                                </div>
                            <div style={{borderTop:"solid", paddingTop:"20px"}}>
                                <h3 style={{fontSize:"20px"}}>PT30 - 9 Pin Cable</h3>
                                <br></br>
                                <a href="mailto:info@dynamiceld.us"><Button size="lg" variant="outline-info"><b>Contact Us</b></Button></a>
                            </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} xs={12}>   
                        <Card style={{height:"600px"}}>
                            <Card.Body>
                                <div style={{paddingBottom:"30px", overflow:"hidden", height:"400px", justifyContent:"center", justifyItems:"center", alignItems:"center", display:"flex"}}>
                                    <Card.Img  src={CABLE3} />
                                </div>
                            <div style={{borderTop:"solid", paddingTop:"20px"}}>
                                <h3 style={{fontSize:"20px"}}>PT30 - OBD II 16 Pin cable (Light- duty for 2008 and newer pickups, vans, cab-forward trucks, etc.)</h3>
                                <br></br>
                                <a href="mailto:info@dynamiceld.us"><Button size="lg" variant="outline-info"><b>Contact Us</b></Button></a>
                            </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} xs={12}>   
                        <Card style={{height:"600px"}}>
                            <Card.Body>
                                <div style={{paddingBottom:"30px", overflow:"hidden", height:"400px", justifyContent:"center", justifyItems:"center", alignItems:"center", display:"flex"}}>
                                    <Card.Img  src={CABLE4} />
                                </div>
                            <div style={{borderTop:"solid", paddingTop:"20px"}}>
                                <h3 style={{fontSize:"20px"}}>PT30 - 6 Pin Cable</h3>
                                <br></br>
                                <a href="mailto:info@dynamiceld.us"><Button size="lg" variant="outline-info"><b>Contact Us</b></Button></a>
                            </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={4} xs={12}>   
                        <Card style={{height:"600px"}}>
                            <Card.Body>
                                <div style={{paddingBottom:"30px", overflow:"hidden", height:"400px", justifyContent:"center", justifyItems:"center", alignItems:"center", display:"flex"}}>
                                    <Card.Img  src={CABLE6} />
                                </div>
                                <div style={{borderTop:"solid", paddingTop:"20px"}}>
                                    <h3 style={{fontSize:"20px"}}>Paccar Adapter</h3>
                                    <br></br>
                                    <a href="mailto:info@dynamiceld.us"><Button size="lg" variant="outline-info"><b>Contact Us</b></Button></a>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Store