import React from 'react'
import { Instagram, Telegram } from 'react-bootstrap-icons';
import { Container, Row, Col } from 'react-bootstrap';
import logo from "../img/logo.jpg"
import { useHistory } from "react-router-dom";

export default function Footer() {
    const history = useHistory();

    function goToHome(e) {
        e.preventDefault();
        window.scrollTo(0, 100);
        history.push('/');
    }

    function goToApplication(e) {
        e.preventDefault();
        window.scrollTo(0, 100);
        history.push('/application');
    }

    function goToProducts(e) {
        e.preventDefault();
        window.scrollTo(0, 100);
        history.push('/products');
    }

    function FooterMenu()
    {
        return (
            <>
            <Container fluid style={{height:"auto"}} className="bg-dark footer">
                <Container className="pt-5 ">
                    <Row className="text-center">
                        <Col className="mb-4" lg="4"> 
                            <img 
                                src={logo}
                                width="auto"
                                height="70px"
                                alt="logo"
                            />
                        </Col>

                        <Col  lg="3" style={{color:"#7E7E7E"}}>
                            <Row className="h4" style={{color:"#ffff"}}><p>Contact Us 24/7</p></Row>
                            <Row> <p>(636) 204-5424</p> </Row>
                            <hr />
                            <Row> <p>info@dynamiceld.us</p> </Row>
                            <Row> <p></p> </Row>
                        </Col>
                        <Col className="mb-4" lg="2" style={{color:"#7E7E7E"}}> 
                            <Row className="h4" style={{color:"#ffff"}}><p>Sitemap</p></Row>
                            <Row> <p className="footerLink" onClick={goToHome}>Home</p></Row>
                            <hr />
                            <Row> <p className="footerLink" onClick={goToProducts}>Products</p></Row>
                            {/* <hr />
                            <Row> <p className="footerLink" onClick={goToApplication}>Features</p> </Row> */}
                        </Col>
                        <Col className="mb-4" lg="3"> 
                            <Row className="h4" style={{color:"#ffff"}}> <p>Follow Us</p> </Row>
                            <Row>
                                <Col>
                                    {/* <Facebook className="icons" color="#f7a120" size={50}/>*/}
                                    <a href="https://t.me/dynamiceld"><Telegram className="icons" color="#f7a120" size={50}/></a>
                                    {/*<Linkedin className="icons" color="#f7a120" size={50}/> */}
                                    <a href="https://www.instagram.com/dynamic.eld/" ><Instagram className="icons" color="#f7a120" size={50}/></a>
                                    {/* <Youtube color="#f7a120" size={50}/> */}
                                </Col>  
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{height:"2vh"}}/>
                    <Row className="pt-4 text-center" style={{color:"#7E7E7E"}}>
                        <p>Dynamic ELD &#169; 2022 / All Rights Reserved</p>
                    </Row>
                </Container>
            </Container>  
        </>
        )
    }

    return (
        <FooterMenu />
    )
}
