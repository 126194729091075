import React from 'react'
import { Card, CardGroup, Container, Row } from 'react-bootstrap'
import Img from "../img/placeholder-image.png"

export default function Application() {
  return (
    <>
        <Container style={{height:"200px"}}></Container>
        <Container className="text-center"> 
            <Row className="justify-content-center"> 
                <h1>Features</h1>
            </Row>
            <Row className="justify-content-center"> <p style={{paddingLeft:"25%", paddingRight:"25%"}} className="myHeader-subtitle">Working hard for something we don’t care about is called stressed, working hard for something we love is called passion.</p></Row>
            <Row className="justify-content-center">

            <CardGroup>
  <Card>
    <Card.Img variant="top" src={Img} />
    <Card.Body>
      <Card.Title>Card title</Card.Title>
      <Card.Text>
        This is a wider card with supporting text below as a natural lead-in to
        additional content. This content is a little bit longer.
      </Card.Text>
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">Last updated 3 mins ago</small>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src={Img} />
    <Card.Body>
      <Card.Title>Card title</Card.Title>
      <Card.Text>
        This card has supporting text below as a natural lead-in to additional
        content.{' '}
      </Card.Text>
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">Last updated 3 mins ago</small>
    </Card.Footer>
  </Card>
  <Card>
    <Card.Img variant="top" src={Img} />
    <Card.Body>
      <Card.Title>Card title</Card.Title>
      <Card.Text>
        This is a wider card with supporting text below as a natural lead-in to
        additional content. This card has even longer content than the first to
        show that equal height action.
      </Card.Text>
    </Card.Body>
    <Card.Footer>
      <small className="text-muted">Last updated 3 mins ago</small>
    </Card.Footer>
  </Card>
</CardGroup>
            </Row>
        </Container>
    </>
  )
}
