import React from "react";
import { HashRouter, Switch, Route} from 'react-router-dom';
import Homepage from "./Homepage";
import About from "./About";
import Application from "./Application"
import Products from "./Products"
import Header from "./Header"
import Footer from "./Footer"
import Store from "./Store";
import { Container } from "react-bootstrap";


function App() {
    return (
        <HashRouter>
            <Container fluid className="appContainer">
                <Header />
                <Switch>
                    <Route exact path="/" component={Homepage} />
                    <Route path="/products" component={Products} />
                    <Route path="/about" component={About} />
                    <Route path="/store" component={Store} />
                    <Route path="/application" component={Application} />
                </Switch>
                <Footer />
            </Container>
        </HashRouter>
    );
}

export default App;
