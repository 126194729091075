import React, { useEffect, useState} from 'react'
import { BrowserView, MobileView} from "react-device-detect";
import { Nav, Navbar, Container, Col, Row} from 'react-bootstrap';
import logo from "../img/logo.jpg"
import { List } from 'react-bootstrap-icons';
import { AiOutlineClose } from 'react-icons/ai'
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}
  

const variants = {
    active: {
        x: [getWindowDimensions().width+getWindowDimensions().width/1.5,125],
        opacity: [0,.5,1],
        transition:
        {
            duration: .5
        }
    },
    inActive: {
        opacity: [1,.5,0],
        x: [125,getWindowDimensions().width+getWindowDimensions().width/1.5],
        transition:
        {
            duration: .5
        }
    }
};

export default function Header() {
    
    var lastScrollTop = 0;
    const $ = (id) => document.getElementById(id);
    const [isToggled, setToggle] = useState(false);
    const history = useHistory();
    
    function clickDropDownIcon()
    {
        setToggle(!isToggled);
        if(isToggled)
        {
            $("dropDownMenuId").style.display = "none";
        }
    }
    

    function goToHome(e) {
        e.preventDefault();
        history.push('/');
        setToggle(false);
    }

    function goToApplication(e) {
        e.preventDefault();
        history.push('/application');
        setToggle(false);
    }

    function goToProducts(e) {
        e.preventDefault();
        history.push('/products');
        setToggle(false);
    }

    function goToStore(e) {
        e.preventDefault();
        history.push('/store');
        setToggle(false);
    }

    function listenToScroll() {
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop){
            $("navbar").hidden = true;
            setToggle(false);
        }
        else{
            if(window.pageYOffset !== 0)
            {   
                $("navbar").hidden = false;
                if(!$("navbar").classList.contains("menu__list--animate-on"))
                {
                    $("navbar").classList.toggle("menu__list--animate-on");
                }
            }
            else {
                $("navbar").style.opacity = "1";
            }
        }
        lastScrollTop = st <= 0 ? 0 : st;
    }

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
    }, )

    function DropDownMenu() {

        return (
            <div id="dropDownMenuId" className='dropDownMenuContainer'>
                <div id="navbar">
                    <Row>
                        <Col>
                            <div className='dropDownMenuIcon' href="/">
                                <img 
                                    src={logo}
                                    opacity="1"
                                    width="200px"
                                    height="auto"
                                    className="d-inline-block align-top"
                                    alt="React Bootstrap logo"
                                />
                            </div>
                        </Col>
                        <Col>
                            <List id="dropDownBTN" onClick={clickDropDownIcon} color="black" size={70}/>
                        </Col>
                    </Row>
                </div>
                <motion.div  style={isToggled ? { x: getWindowDimensions().width+getWindowDimensions().width/1.5 } : {x: 125}} animate={isToggled ? "active" : "inActive"} variants={variants} className='justify-content-center dropDownMenuContainer fixed-top'>
                    <div id="dropDownMenuID" className='dropDownMenu'>
                        <div>
                        <AiOutlineClose id="dropDownBTN" onClick={clickDropDownIcon} color="#ffff" size={60}/>
                        </div>
                        <div onClick={goToHome} className='dropDownMenuItem' ><h5>Home</h5></div>
                        <hr />
                        <div onClick={goToProducts} className='dropDownMenuItem' ><h5>Products</h5></div>
                        <hr />
                        <div onClick={goToProducts} className='dropDownMenuItem' ><h5>Store</h5></div>
                        <hr />
                        {/* <div onClick={goToApplication} className='dropDownMenuItem' ><h5>Features</h5></div>
                        <hr /> */}
                    </div>
                </motion.div>
            </div>
        )
    }

    return (
        <>
            <BrowserView>
                <Navbar id="navbar" className="justify-content-center fixed-top navHome navBarIcon">
                    <Container>
                        <Navbar.Brand href="/">
                            <img 
                                onClick={goToHome}
                                src={logo}
                                opacity="1"
                                width="300px"
                                height="auto"
                                className="d-inline-block align-top"
                                alt="React Bootstrap logo"
                                />
                            <p className="subtitle">Built to Perfect</p>
                        </Navbar.Brand>

                        <Nav>
                            <Nav.Link style={{color:"white"}} href="/" >Home</Nav.Link>
                            <Nav.Link style={{color:"white"}} onClick={goToProducts}>Products</Nav.Link>
                            <Nav.Link style={{color:"white"}} onClick={goToStore}>Store</Nav.Link>
                            {/* <Nav.Link style={{color:"white"}} onClick={goToApplication}>Features</Nav.Link> */}
                        </Nav>
                    </Container>
                </Navbar>
            </BrowserView>
            
            <MobileView>
                <div className="fixed-top">
                    <DropDownMenu />
                </div>
            </MobileView>
        </>
    )
}
